import React, { Component } from 'react';

import Form from 'common/components/Forms/Form';
import Input from 'common/components/Forms/Input';
import Checkbox from 'common/components/Forms/Checkbox';
import Button from 'common/components/Button';

class BasicFormDemo extends Component {
  state = {
    myInput: {
      value: '',
      valid: true,
      errorMessage: ''
    },
    myCheckbox: {
      checked: 'indeterminate',
      valid: true,
      errorMessage: ''
    }
  };

  onMyInputChange = (e) => {
    // dummy check; just set it to invalid if the text is "invalid"
    const value = e.target.value;
    let valid = true;
    let errorMessage = '';
    if (value === 'invalid') {
      valid = false;
      errorMessage = 'You entered "invalid"';
    }

    this.setState({ myInput: { value, valid, errorMessage } });
  };

  onMyCheckboxChange = (e) => {
    const checked = e.target.checked;
    this.setState({ myCheckbox: { checked, valid: true, errorMessage: '' } });
  };

  // first argument is the event, second is the DOM node of the form
  onMyFormSubmit = (event) => {
    event.preventDefault();

    const { myInput, myCheckbox } = this.state;

    let valid = myInput.valid && myCheckbox.valid;

    // obviously this is the most basic example with a few inputs
    // for better validation of multiple inputs, see the redux example
    if (myInput.value === '') {
      this.setState({ myInput: { value: '', valid: false, errorMessage: 'This field is required' } });
      valid = false;
    }

    if (myCheckbox.checked !== true) {
      this.setState({
        myCheckbox: {
          checked: myCheckbox.checked,
          valid: false,
          errorMessage: 'You have to agree to whatever this checkbox says'
        }
      });
      valid = false;
    }

    if (valid) {
      alert(`Submitted!\n${myInput.value}, ${myCheckbox.checked}`);

      // PROTIP: calling ".submit()" on the DOM node directly skips the onSubmit event and just
      // does whatever you have set for "action"
      // We're not actually going to do the submit here since that will reload the page...
      // event.currentTarget.submit();
    }
  };

  render() {
    const { myInput, myCheckbox } = this.state;

    return (
      <Form action="/form_submit_path" onSubmit={this.onMyFormSubmit}>
        <Input
          label="My Input"
          id="myInput"
          name="myInput"
          className="my-input-class"
          placeholder="Type 'invalid' to see form validation"
          onChange={this.onMyInputChange}
          value={myInput.value}
          valid={myInput.valid}
          errorMessage={myInput.errorMessage}
          required
          autoFocus
        />
        <Checkbox
          label="My Checkbox"
          id="myCheckbox"
          name="myCheckbox"
          required
          onChange={this.onMyCheckboxChange}
          valid={myCheckbox.valid}
          errorMessage={myCheckbox.errorMessage}
          checked={myCheckbox.checked}
        />
        <Button type="submit" variant="primary">
          Submit
        </Button>
      </Form>
    );
  }
}

export default BasicFormDemo;
