import React from 'react';
import ReactDOM from 'react-dom';

import UncontrolledFormDemo from './UncontrolledFormDemo';
import BasicFormDemo from './BasicFormDemo';
import ReduxValidatedFormDemo from './ReduxValidatedFormDemo';

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(<UncontrolledFormDemo />, document.getElementById('uncontrolled-form-demo'));

  ReactDOM.render(<BasicFormDemo />, document.getElementById('basic-form-demo'));

  ReactDOM.render(<ReduxValidatedFormDemo />, document.getElementById('redux-validated-form-demo'));
});
