import React, { Component } from 'react';
import { Provider } from 'react-redux';

import {
  createDefaultInputsState,
  createStoreWithFormReducer
} from 'common/components/Forms/redux/FormReduxUtils';

import ReduxValidatedFormDemoForm from './ReduxValidatedFormDemoForm';
import { validateFirstInput, validateSecondInput } from './ReduxValidatedFormDemoValidations';

class ReduxValidatedFormDemo extends Component {
  constructor(props) {
    super(props);

    // "createDefaultInputsState" will create the default "inputs" state with all the required values
    const defaultState = {
      inputs: createDefaultInputsState(
        {
          firstInput: {
            id: 'firstInput',
            label: 'First Input',
            placeholder: 'This input has to have at least five characters',
            required: true
          },
          secondInput: {
            id: 'secondInput',
            label: 'Second Input',
            placeholder: "This input has to match what's in the first input",
            required: true,
            children: <div style={{ fontSize: '10px', color: 'green' }}>You can also add components here</div>
          },
          thirdInput: {
            id: 'thirdInput',
            label: 'Third Input',
            placeholder: 'This input is optional',
            children: <div style={{ fontSize: '12px', marginLeft: '10px' }}>(Optional)</div>
          },
          firstCheckbox: {
            id: 'firstCheckbox',
            label: 'A Checkbox',
            type: 'checkbox'
          },
          secondCheckbox: {
            id: 'secondCheckbox',
            label: 'A Required Checkbox',
            type: 'checkbox',
            required: true
          }
        },
        true // includeRecaptcha
      )
    };

    const validations = {
      firstInput: validateFirstInput,
      secondInput: validateSecondInput
    };

    // "createStoreWithFormReducer" will create a store to use with the default form reducer, which handles _most_ things
    this.store = createStoreWithFormReducer(defaultState, validations, 'ReduxValidatedFormDemo');
  }

  render() {
    return (
      <Provider store={this.store}>
        <ReduxValidatedFormDemoForm />
      </Provider>
    );
  }
}

export default ReduxValidatedFormDemo;
