import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from 'common/components/Button';
import Form from 'common/components/Forms/Form';
import ReduxValidatedFormReCaptcha from 'common/components/Forms/redux/ReduxValidatedFormReCaptcha';
import { validateForm } from 'common/components/Forms/redux/FormReduxActions';
import { renderInputsFromState } from 'common/components/Forms/redux/FormReduxUtils';

class ReduxValidatedFormDemoForm extends Component {
  validateAndSubmitForm = (event) => {
    const { onFormSubmit } = this.props;

    // if you don't preventDefault here, the form will do its default action
    event.preventDefault();

    // this function is called only if the form passes validation in the reducer
    onFormSubmit((inputs) => {
      console.log(inputs);
      alert('You submitted the form! 🎉\nLook at the console to see submitted inputs 💻');

      // this can be done if you want to just do the "default" form action
      // submitting the DOM node directly skips firing an onSubmit event
      // event.currentTarget.submit()
    }, event);
  };

  render() {
    // if your form results in a redirect, it's suggested that you pass:
    // busy={formSubmitted}
    // as a prop to the submit Button, so that it shows a spinner as the page redirects!
    // We don't do it for this demo because there's no redirect and no async action going on
    // const { formSubmitted } = this.props;
    const { inputs } = this.props;

    return (
      <Form onSubmit={this.validateAndSubmitForm}>
        {/*
          renderInputsFromState just renders a ReduxValidatedInput for every object in the inputs array
          This is suitable for most basic forms; for more advanced layouts just render each one separately i.e.:
          <ReduxValidatedInput name="firstInput" />
          <ReduxValidatedInput name="secondInput" />
          <hr />
          <ReduxValidatedInput name="thirdInput" />
          <ReduxValidatedCheckbox name="firstCheckbox" />
        */}
        {renderInputsFromState(inputs)}
        <ReduxValidatedFormReCaptcha />
        <Button style={{ marginTop: '10px' }} size="lg" variant="primary" dark type="submit">
          Submit
        </Button>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  inputs: state.inputs,
  formSubmitted: state.formSubmitted
});

const mapDispatchToProps = {
  onFormSubmit: validateForm
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReduxValidatedFormDemoForm);
