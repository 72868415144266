import React, { Component } from 'react';

import Form from 'common/components/Forms/Form';
import Input from 'common/components/Forms/Input';
import UncontrolledCheckbox from 'common/components/Forms/UncontrolledCheckbox';
import Button from 'common/components/Button';

class UncontrolledFormDemo extends Component {
  inputRef = React.createRef();
  checkboxRef = React.createRef();

  // first argument is the event, second is the DOM node of the form
  onMyFormSubmit = (event) => {
    event.preventDefault();

    alert(`Submitted!\nInput: ${this.inputRef.current.value}\nCheckbox: ${this.checkboxRef.current.checked}`);

    // PROTIP: calling ".submit()" on the DOM node directly skips the onSubmit event and just
    // does whatever you have set for "action"
    // We're not actually going to do the submit here since that will reload the page...
    // event.currentTarget.submit();
  };

  render() {
    return (
      <Form action="/form_submit_path" onSubmit={this.onMyFormSubmit}>
        <Input
          label="My Input"
          id="myUncontrolledInput"
          name="myUncontrolledInput"
          className="my-input-class"
          placeholder="No validation here, folks"
          autoFocus
          ref={this.inputRef}
        />
        <UncontrolledCheckbox
          defaultChecked
          label="My Checkbox"
          id="myUncontrolledCheckbox"
          name="myUncontrolledCheckbox"
          ref={this.checkboxRef}
        />
        <Button type="submit" variant="primary">
          Submit
        </Button>
      </Form>
    );
  }
}

export default UncontrolledFormDemo;
