export const validateFirstInput = state => {
  const { inputs } = state;
  const { value } = inputs.firstInput;

  if (!value.length || value.length < 5) {
    return {
      ...state,
      inputs: {
        ...state.inputs,
        firstInput: {
          ...inputs.firstInput,
          valid: false,
          errorMessage: 'First Input must be more than 5 characters'
        }
      }
    };
  }

  return state;
};

export const validateSecondInput = state => {
  const { inputs } = state;
  const { value } = inputs.secondInput;
  const firstInputValue = inputs.firstInput.value;

  if (value !== firstInputValue) {
    return {
      ...state,
      inputs: {
        ...state.inputs,
        secondInput: {
          ...inputs.secondInput,
          valid: false,
          errorMessage: 'Second Input must match First Input'
        }
      }
    };
  }

  return state;
};
